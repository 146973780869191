import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export default function Hero(props) {
  return (
    <section className="section relative p-0 sm:items-start">
      {props.video && (
        <video
          className="absolute object-cover w-screen h-screen -z-10 filter bg-gray-900"
          autoPlay="autoplay"
          loop="loop"
          muted
          playsInline
        >
          <source src={props.videoMp4} type="video/mp4" />
          <source src={props.videoWebm} type="video/webm" />
        </video>
      )}
      {props.image && (
        <>
          <div className="absolute bottom-0 left-0 right-0 pt-20 bg-gradient-to-t from-black"></div>
          <div className="absolute h-screen w-screen -z-10">
            <div className="w-full h-full">{props.image}</div>
          </div>
        </>
      )}
      <div
        className="relative z-10 text-white h-screen w-full flex flex-col justify-center sm:justify-end md:justify-center items-center sm:items-start px-8 lg:px-5vw"
        id="hero"
      >
        <div className="relative flex flex-col items-center sm:items-start text-center sm:text-left sm:pb-8 ">
          <h1 className={props.titleClassName}>{props.title}</h1>
          {props.children}
        </div>
        {props.banner && (
          <div className="absolute hidden md:block lg:flex lg:flex-row lg:justify-between bottom-0 left-0 right-0 py-5 mx-8 lg:mx-5vw border-t border-white opacity-80">
            <p className="mb-2">We've created campaigns for...</p>
            <ul className="flex flex-row">
              <li className="h-6 w-16 flex flex-row items-center ">
                <StaticImage
                  src="../images/client-logos/logo-zip.png"
                  objectFit="contain"
                  alt="Zip logo"
                  placeholder="tracedSVG"
                />
              </li>
              <li className="h-6 w-24 flex flex-row items-center ml-4">
                <StaticImage
                  src="../images/client-logos/logo-sharp.png"
                  objectFit="contain"
                  alt="Sharp logo"
                  placeholder="tracedSVG"
                />
              </li>
              <li className="h-6 w-24 flex flex-row items-center ml-4">
                <StaticImage
                  src="../images/client-logos/logo-ray-white.png"
                  objectFit="contain"
                  alt="Ray White logo"
                  placeholder="tracedSVG"
                />
              </li>
              <li className="h-6 w-24 flex flex-row items-center ml-4">
                <StaticImage
                  src="../images/client-logos/logo-freelancer.png"
                  objectFit="contain"
                  alt="Freelancer logo"
                  placeholder="tracedSVG"
                />
              </li>
            </ul>
          </div>
        )}
      </div>
    </section>
  )
}

Hero.defaultProps = {
  titleClassName: "w-48 sm:w-36 md:w-60 lg:w-7/12",
}
