import React, { useState } from "react"
import { Formik, Form, useField } from "formik"
import * as Yup from "yup"
import Botpoison from "@botpoison/browser"

const FORMSPARK_ACTION_URL = "https://submit-form.com/kmTvAth3"
const botpoison = new Botpoison({
  publicKey: "pk_9ff4f596-8b4d-4196-b80c-402d96b5fc2b",
})
// const sleep = ms => new Promise(r => setTimeout(r, ms))

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <>
      <label htmlFor={props.id || props.name} className="sr-only">
        {label}
      </label>
      <input
        className="px-3 py-2 rounded-md bg-gray-100"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-red-600">{meta.error}</div>
      ) : null}
    </>
  )
}

const TextAreaInput = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props, type: "textarea" })
  return (
    <>
      <label htmlFor={props.id || props.name} className="sr-only">
        {label}
      </label>
      <textarea
        rows={3}
        className="px-3 py-2 rounded-md bg-gray-100"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-red-600">{meta.error}</div>
      ) : null}
    </>
  )
}

export default function ContactForm() {
  const [submitted, setSubmitted] = useState(false)

  return (
    <>
      <div id="contact" className="w-full p-6 lg:max-w-3xl">
        <h2 className="mb-6 lg:text-5xl lg:leading-tight lg:mb-8">
          Contact us
        </h2>
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            message: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Your name is required."),
            email: Yup.string()
              .email("Invalid email address.")
              .required("Your email is required."),
          })}
          onSubmit={async (values, { resetForm }) => {
            // await sleep(500)
            const { solution } = await botpoison.challenge()
            await fetch(FORMSPARK_ACTION_URL, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                accept: "application/json",
              },
              body: JSON.stringify({
                ...values,
                _botpoison: solution,
              }),
            })
            resetForm()
            setSubmitted(true)
            // await sleep(500)
            // console.log(JSON.stringify(values, null, 2), submitted)
          }}
        >
          {({ isSubmitting }) =>
            submitted ? (
              <div className="flex flex-col items-center mt-8">
                <p className="p-5 bg-primary rounded-md text-white">
                  Success! We'll be in touch with you as soon as possible.
                </p>
              </div>
            ) : (
              <Form className="grid grid-cols-1 gap-4 lg:gap-6 sm:w-4/6 sm:mx-auto justify-center items-center text-sm font-normal">
                <TextInput
                  label="Name"
                  name="name"
                  type="text"
                  placeholder="Name *"
                />
                <TextInput
                  label="Email Address"
                  name="email"
                  type="email"
                  placeholder="Email *"
                />
                <TextInput
                  label="Phone Number"
                  name="phone"
                  type="text"
                  placeholder="Phone Number"
                />
                <TextAreaInput
                  label="Message"
                  name="message"
                  placeholder="Leave us a message."
                />
                <input
                  type="checkbox"
                  name="_honeypot"
                  style={{ display: "none" }}
                  tabindex="-1"
                  autocomplete="off"
                ></input>
                <button
                  type="submit"
                  className="py-2 px-6 lg:mt-4 uppercase font-medium bg-primary text-white rounded-md hover:bg-primary-lighter duration-300 mx-auto disabled:opacity-30"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </button>
              </Form>
            )
          }
        </Formik>
        {/* <form
          method="POST"
          action={FORMSPARK_ACTION_URL}
          className="grid grid-cols-1 gap-4 justify-center items-center text-sm font-normal"
        >
          <input
            name="name"
            placeholder="Name *"
            className="px-3 py-2 rounded-md bg-gray-100"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email *"
            className="px-3 py-2 rounded-md bg-gray-100"
          />
          <input
            name="phone-number"
            placeholder="Phone number"
            className="px-3 py-2 rounded-md bg-gray-100"
          />
          <textarea
            name="message"
            placeholder="Leave us a message"
            className="px-3 py-2 rounded-md bg-gray-100"
          />
          <button
            type="submit"
            className="py-2 px-6 uppercase font-medium bg-primary text-white rounded-md hover:bg-primary-lighter duration-300 mx-auto"
          >
            Submit
          </button>
        </form> */}
      </div>
    </>
  )
}
